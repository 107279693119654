import React from 'react'
import SurveyRouter from 'routes'
import { Mask, SpinLoading } from 'antd-mobile'
import { useAppSelector } from 'hooks/reduxHooks'

const App: React.FC = () => {
  const loading = useAppSelector((state) => state.commonReducer.loading)
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <SurveyRouter />
      {loading ? (
        <Mask
          visible={loading}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div style={{ color: '#fff', fontSize: '4rem' }}>
            <SpinLoading
              color="currentColor"
              style={{
                '--size': '4rem',
              }}
            />
          </div>
        </Mask>
      ) : null}
    </div>
  )
}

export default App
