import React from 'react'
import './IpCountryToProWebsite.scss'
import utils from '../../utils/utils'

interface props {
  countryName: string
}

const ToProWebsite: React.FC<props> = ({ countryName }) => {
  const hostname: string = window.location.hostname
  const baseUrl = utils.getCurrentEnvConfig().globalBaseurl
  const websitUrl: string = `${baseUrl}/api/masterdata-service/location/redirect/officialWebsite`
  const appDownloadUrl: string = `https://${hostname}/referral/visitorTourIndex?country=${countryName}&language=en&from=scanProduct&baseurl=https://adminq2.bosch-ptqr.com`
  const trybToProWebSite = (status: string) => {
    window.location.href = status === 'website' ? websitUrl : appDownloadUrl
  }

  return (
    <div className="container">
      <div className="header-colorline"></div>
      <div className="header-container"></div>
      <div className="content">
        <h3>SORRY!</h3>
        <span>
          The country/region <b>{countryName}</b> you are located is not served
          by BeConnected.
        </span>
        <p>
          Or you might visit the
          <a
            onClick={() => trybToProWebSite('website')}
            className="link-website"
          >
            Bosch website
          </a>
          to get updated on Bosch products and marketing info.
        </p>
      </div>
      <div className="button-container">
        <div className="warp">
          <a
            onClick={() => trybToProWebSite('website')}
            className="pro_website"
          >
            Go to Bosch website
          </a>
          <a onClick={() => trybToProWebSite('download')}>APP download</a>
        </div>
      </div>
    </div>
  )
}

export default ToProWebsite
