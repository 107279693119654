import { 
  getLocationFromBaiduApiByIp,
  getLocationIpInfo,
  getCountryList
} from '../master-data-service'

export default {
  getLocationFromBaiduApiByIp,
  getLocationIpInfo,
  getCountryList
}
